.requestcallbackContainer,.thankyouContainer {
    padding: 32px 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .25);
    z-index: 1000;
    width: 600px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
}
.requestcallbackContainer p{
    margin: 0px !important;
    text-align: center;
}
.crossbox {
    cursor: pointer;
    float: right;
}

.requestcallBackCon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    
}

.reqText {
    font-weight: bold;
    font-size: 32px;
    color: #fa3131;
}

.rcqsubheading,.isSuperUrgent {
    font-weight: 500;
    font-size: 32px;
}

.isSuperUrgent {
   color: #B8B8B8;
}

.recContactNumber {
     font-size: 500;
     color: #fa3131;
     font-size: 24px;
     cursor: pointer;
}


.overlay {
    position: fixed;
    top: 0px;
    display: block;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #101223;
    opacity: 0.3;
    z-index: 900;
}
.thankyouContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.thankyouContainer p {
    margin: 0px;
}
.getbacktxt {
    font-size: 32px;
    font-weight: 500;
}

.thankyoutxt {
    color: #fa3131;
    font-weight: 700;
    font-size: 32px;
}

.okbtn {
    padding: 16px 32px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    background-color: #fa3131;
    border-radius: 16px;
    cursor: pointer;
    width: fit-content;
    margin-top: 16px;
}

.recactionCon {
    width: fit-content;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
     gap: 16px;
    justify-content: center;
    /* padding: 16px; */
}


.recactionCon input {
   padding: 16px 0px 16px 32px;
   border: 0px;
   border-radius: 16px;
   font-size: 20px;
}
.recactionCon input:focus {
    border: 0px;
    outline: none;
    
}
.formcon {
    display: flex;
    width: 100%;
}


.recCallme {
    background-color: #fa3131;
    color: #fff;
    font-size: 21px;
    padding: 16px;
    border-radius:0px 16px 16px 0px ;
    cursor: pointer;
}

.recCallme:hover {
    background-color: #fa3140;
}

.mobilerror {
    color: red;
    font-size: 18px;
}


@media (min-width: 320px) and (max-width: 480px) {  
    .requestcallbackContainer {
        width: 90%;
        margin: auto;
        padding: 16px;
    }
    .requestcallBackCon {
        gap: 10px;
    }
    .rcqsubheading, .isSuperUrgent {
        font-size: 24px;
    }
    .recCallme {
        font-size: 18px;
        width: 112px;
    }
    .recactionCon {
        width: 90%;
        margin: auto;
    }
    .recactionCon input {
        width: 100%;
        padding: 8px 16px;
        font-size: 16px;
    }
    .requestcallbackContainer p {
        font-size: 16px;
    }
    .thankyouContainer{
            width: 90%;
            margin: auto;
            padding: 16px 10px;
        gap: 16px;
    }
    .thankyouContainer p{
        text-align: center;
        font-size: 20px;
    }
    .okbtn {
        font-size: 16px;
        margin-top: 0px;
    }
}

@media (min-width: 121px) and (max-width: 320px){  
    .requestcallbackContainer {
        width: 80%;
    }
    .recCallme {
        font-size: 14px;
    }
    .requestcallbackContainer p {
        font-size: 14px;
    }
}